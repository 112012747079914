.toaster {
	position: fixed;
	z-index: 10000000;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 50px;
	overflow: hidden;
	text-align: center;
	pointer-events: none;
}

.toast {
	display: inline-block;
	height: 32px;
	line-height: 32px;
	padding: 0px 11px;
	margin-top: 55px;
	border-radius: 5px;
	transition: margin-top 0.25s ease-out;
	color: #efefef;
	background-color: rgba(0, 0, 0, 0.8);
	box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.24);
}

.toast.slide-in {
	margin-top: 0px;
}

.toast.slide-out {
	margin-top: 55px;
}

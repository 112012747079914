.vr-starter {
	position: absolute;
	z-index: 20;
	bottom: 24px;
	left: 24px;
	padding: 0px 14px;
	height: 28px;
	line-height: 28px;
	font-size: 10px;
	border: 1px solid rgba(255, 255, 255, 0.9);
	border-radius: 16px;
	background-color: rgba(10, 10, 10, 0.4);
	color: rgba(255, 255, 255, 0.9);
	outline-style: none;
	cursor: pointer;
}

.vr-starter:hover {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #000000;
}

.vr-starter.disabled {
	border-color: rgba(255, 255, 255, 0.45);
	color: rgba(255, 255, 255, 0.45);
	cursor: auto;
}

.vr-starter.disabled:hover {
	background-color: rgba(0, 0, 0, 0.4);
	border-color: rgba(255, 255, 255, 0.7);
	color: rgba(255, 255, 255, 0.7);
}

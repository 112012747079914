.Graph{
 
    /* position: fixed; */

    font-family: sans-serif;
    z-index: 200;
    display: block;
    overflow: hidden;
    border: 1px solid #cccccc;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0 

    /* overflow: scroll; */
}
.Node {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    background-color: rgb(27, 27, 27);
    border-radius: 5px;
    padding: 0px;
    user-select: none;
    
}

.GraphSelect {
    background-color: #333333;
    border-radius: 5px;
    color:#cccccc;
}
.NodeTitle {   
    height: 20px;
    width: 100%;
    position: relative;
    top:0px;
    left:0px;
    font-weight: bold;
    padding-top:5px;
    color:#aaaaaa;
    text-align: center;
    font-size:14px;
    cursor: pointer;
    z-index: 3;
    user-select: none;
}
.NodeRow{
    height: 20px;
    width: calc( 100% + 10px );
    position: relative;
    top:0px;
    left:0px;
    color:#ffffff;
    margin-top:3px;
    user-select: none;
}
.NodeRow.Col0{
    background-color: #333333;
    left:-10px;
}
.NodeRow.Col1{
    background-color: #555555;
    
}


/*
.SocketIn{
    background-color:#333333;
    width:15px;
    height: 100%;
    position: absolute;
    top:0px;
    left:-15px;
}
.SocketOut{
    background-color:#444444;
    width:15px;
    height: 100%;
    position: absolute;
    top:0px;
    right:-15px;
}
*/
.NameIn {
    position: absolute;
    top:3px;
    left:50%;
    width:50%;
    height: 100%;
    overflow:hidden;
    color:#cccccc;
    font-size:12px;
}
.ValueIn {
    position: absolute;
    top:2px;
    left:5%;
    width: 40%;
    height: 17px;
    overflow:hidden;
    font-family: monospace;
}
.NameOut {
    position: absolute;
    top:3px;
    right:50%;
    width:50%;
    height: 100%;
    overflow:hidden;
    color:#cccccc;
    font-size:12px;
    text-align: right;
}
.ValueOut {
    position: absolute;
    top:2px;
    right:5%;
    width: 40%;
    height: 17px;
    overflow:hidden;
    font-family: monospace;
    text-align: right;
}

.NodeRow.Col0.InSelected {
    background-color:   #00802b
    ;
}
.NodeRow.Col1.InSelected {
    background-color:   #00802b
    ;
}

.NodeRow.Col0.OutSelected {
    background-color:   #00cc44
    ;
}
.NodeRow.Col1.OutSelected {
    background-color:   #00cc44
    ;
}

.NodeRow.Clicked {
    transform: scale(1.5)
    ;
}

.UI {
    position: absolute;
    bottom: 0;
    left: 0;
 
    z-index: 8;

    background-color: rgb(27, 27, 27);
    border-radius: 5px;
    padding: 6px;
    user-select: none;

}

.Button {
    margin: 5px;
    background-color: #333333;
    border-radius: 5px;
    color:#cccccc;
    padding: 8px;
    
}

.Button:hover {
    opacity: 0.8;
    cursor: pointer;
  
}

.Hidden {
    display: none;
}

.Dropdown {
    display: absolute
}

.DropdownOption {
    border-radius: 5px;
    text-align: center;
    padding: 2px
}
.DropdownOption:hover {
    opacity: 0.8;
    cursor: pointer;
    transform: scale(1.2);
}
.DropdownOption.Row0{
    background-color: #333333;
    left:-10px;
}
.DropdownOption.Row1{
    background-color: #555555;
    
}

.CloseBtn {
    background-color: red;
}


.VisibleG {
    opacity: 0.8;
    animation: fadeInRightG 0.5s ;
    display: block
   
   

}
.InvisibleG {
    opacity: 0;
    animation: fadeOutRightG 1s ;
    display: none ;
    z-index: -1;
    

}


.Pointer {
    cursor: pointer
}

.DefaultCursor {
    cursor: default
}


@keyframes fadeInRightG {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
     
    }
 
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  

  @keyframes fadeOutRightG {
    from {
      opacity: 1;
      z-index : 1000;

    
      
    }
  
 
    to {
      opacity: 1;
      z-index : - 1000;
      transform: translate3d(100%, 0, 0);
    
    }
  }
 

  .SelectedNode {
      border: 2px solid white;
  }
.TabDiv{
}

.TabDiv .Tabs{
	width:100%;
	height:20px;
}

.TabDiv .Tabs:after{
	clear:both;
	visibility: hidden;
  	display: block;
  	font-size: 0;
  	content: " ";
  	clear: both;
  	height: 0;
}

.TabDiv .Divs{
	width:100%;
	height: auto;
}

.TabDiv .Tabs .Tab{
	width:50px;
	border:1px solid #666666;
	float:left;
	cursor: pointer;
}

.TabDiv .Tabs .Tab.Active{
	border:2px solid #ffffff;	
}

.TabDiv .Divs .Div{
	display: none;
}

.TabDiv .Divs .Div.Active{
	display: block;
}